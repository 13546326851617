<template>
  <div class="relatorio-dre-init">
    <v-container fluid>
      <h1 class="titulo">Relatório de Pagamentos Tesouraria</h1>
      <v-form>
        <v-row justify="end">
          <v-col cols="12" sm="2">
            <v-menu :close-on-content-click="true" :nudge-right="35" transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="selectedDateString"
                  label="Data"
                  prepend-icon="mdi-calendar"
                  v-on="on"
                  readonly/>
              </template>
              <v-date-picker
                v-model="selectedDate"
                locale="pt-br"
                scrollable
                @input="closeModalDatePicker(selectedDate)"
              />
            </v-menu>
          </v-col>

          <v-col cols="12" sm="2">
            <v-select
              :items="groupByList"
              label="Agrupor por"
              v-model="selectedGroupBy"
              @input="$v.selectedGroupBy.$touch()"
              @blur="$v.selectedGroupBy.$touch()">>
            </v-select>
          </v-col>

          <v-col cols="12" sm="2">
            <v-select
              :disabled="!canChooseSupervisor && !canChoosePromoter"
              :loading="!canChooseSupervisor && !canChoosePromoter"
              :items="selectedGroupBy.id === 2 ? supervisorList : promoterList"
              :error-messages="initialErrors"
              label="Inicial"
              v-model="initial"
              @input="$v.initial.$touch()"
              @blur="$v.initial.$touch()">
            </v-select>
          </v-col>

          <v-col cols="12" sm="2">
            <v-select
              :disabled="!canChooseSupervisor && !canChoosePromoter"
              :loading="!canChooseSupervisor && !canChoosePromoter"
              :items="selectedGroupBy.id === 2 ? supervisorList : promoterList"
              :error-messages="finalErrors"
              label="Final"
              v-model="final"
              @input="$v.final.$touch()"
              @blur="$v.final.$touch()">
            </v-select>
          </v-col>
        </v-row>
      </v-form>

      <v-row>
        <v-col class="text-right">
          <v-btn @click.prevent="buscar" color="info" :loading="isLoading" @mouseover="visible = true">Buscar</v-btn>
        </v-col>
      </v-row>

      <v-row v-if="!hasResult">
        <h1>Nenhum resultado para mostrar.</h1>
      </v-row>

      <v-col v-else>
        <v-row>
          <v-col md="2">
            <v-alert color="blue" dense outlined>
              <div class="title">Data Atual</div>
              <div>{{dataAtual}}</div>
            </v-alert>
          </v-col>

          <v-col md="2">
            <v-alert color="blue" dense outlined>
              <div class="title">Data da Busca</div>
              <div>{{dataBusca}}</div>
            </v-alert>
          </v-col>
        </v-row>

        <v-col v-for="(group, index) in items" :key="index">
          <v-col>
            <v-simple-table dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Supervisor</th>
                    <th class="text-left">Promotor</th>
                    <th class="text-left">Agência</th>
                    <th class="text-left">Valor</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in group.payments" :key="item.agency_id">
                    <td>{{ item.supervisor_cod + ' - ' + item.supervisor_name }}</td>
                    <td>{{ item.promoter_cod + ' - ' + item.promoter_name }}</td>
                    <td>{{ item.agency_cod + ' - ' + item.agency_name }}</td>
                    <td>{{ item.value | currency }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
          
          <v-col>
            <v-row justify="end">
              <v-col md="2">
                <v-alert color="success" dense outlined>
                  <div class="title">Subtotal</div>
                  <div>{{group.subTotal | currency}}</div>
                </v-alert>
              </v-col>
            </v-row>
          </v-col>
        </v-col>

        <v-row>
          <v-col md="2">
            <v-alert color="blue" dense outlined>
              <div class="title">Total</div>
              <div>{{total | currency}}</div>
            </v-alert>
          </v-col>
        </v-row>
      </v-col>
    </v-container>
  </div>
</template>

<script>
import moment from 'moment'
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import validator from './validator.js'

const namespaceStore = 'pagamentos'

export default {
  name: 'Pagamentos',
  mixins: [],
  data: () => ({
    groupByList: [{ text: '2 - Supervisor', value: { id: 2, key: 'supervisor_cod' }}, { text: '3 - Promotor', value: { id: 3, key: 'promoter_cod' } }],
    selectedGroupBy: { id: 2, key: 'supervisor_cod' },
    dataAtual: null,
    dataBusca: null,
    selectedDateString: moment().format('DD/MM/YYYY'),
    selectedDate: moment().format('YYYY-MM-DD'),
    canChooseSupervisor: false,
    canChoosePromoter: false,
    initial: null,
    final: null,
    isLoading: false,
    hasResult: false
  }),
  mounted () {
    const self = this
    self.getSupervisor().then(()=>{ self.canChooseSupervisor = true }).catch((e)=>{console.log(e)})
    self.getPromoter().then(()=>{ self.canChoosePromoter = true }).catch((e)=>{console.log(e)})
  },

  computed: {
    ...validator,
    ...mapGetters(namespaceStore, ['items', 'submitDate', 'supervisorList', 'promoterList', 'total'])
  },
  methods: {
    ...mapActions(namespaceStore, ['submit', 'getSupervisor', 'getPromoter']),
    buscar () {
      const self = this
      self.$v.$touch()
      if (!self.$v.$invalid) {
        self.isLoading = true
        const data = {
          groupBy: self.selectedGroupBy.key,
          initial: parseInt(self.initial.cod),
          final: parseInt(self.final.cod),
          date: self.selectedDate
        }
        self.submit(data).then( async () => {
          self.dataAtual = moment(self.submitDate).format('DD/MM/YYYY HH:mm:ss')
          self.dataBusca = moment(self.selectedDate).format('DD/MM/YYYY')
          self.isLoading = false
          self.hasResult = true
        }).catch(() => {
          self.isLoading = false
        })
      }
    },

    closeModalDatePicker (date) {
      this.selectedDateString = moment(date).format('DD/MM/YYYY')
    }
  },
  validations () {
    return {
      selectedGroupBy: { required },
      initial: { required },
      final: { required }
    }
  }
}
</script>