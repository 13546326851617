import { REQUIRED_FIELD } from '@/utils/validationMessagesUtils'

export default {
  initialErrors () {
    const errors = []
    if (!this.$v.initial.$dirty) return errors
    !this.$v.initial.required && errors.push(REQUIRED_FIELD)
    return errors
  },
  finalErrors () {
    const errors = []
    if (!this.$v.final.$dirty) return errors
    !this.$v.final.required && errors.push(REQUIRED_FIELD)
    return errors
  }
}